import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Container, Row, Col } from "reactstrap"
import { MediaQueries } from "../../utils/responsive"
import Card from "../Card"

const StyledCardHolder = styled(Container)`
  display: flex;
  padding: 0;
  margin-top: ${props => `${props.theme.margins.verticalDesktop * 1.2}px`};
  margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
  @media ${MediaQueries.mobile} {
    margin-top: ${props => `${props.theme.margins.verticalDesktop}px`};
    margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
  }

  .card-col {
    margin-bottom: ${props => `${props.theme.margins.verticalMobile}px`};
    @media ${MediaQueries.desktop} {
      margin-bottom: 0;
    }
  }
`

const TarsadalmiCardHolder = props => {
  const { i18n } = useTranslation("shop")
  const language = i18n.language
  return (
    <StyledCardHolder>
      <Row>
        {props.cards.map((c, i) => (
          <Col
            xs={12}
            sm={6}
            lg={4}
            key={c.icon.asset.id + Math.random()}
            className="card-col"
          >
            <Card
              image={
                c.icon.asset.extension === "svg"
                  ? `https://cdn.sanity.io/${c.icon.asset.path}`
                  : c.icon.asset.gatsbyImageData
              }
              title={c._rawTitle[language]}
              description={c._rawBoxDescription[language]}
              index={i}
            />
          </Col>
        ))}
      </Row>
    </StyledCardHolder>
  )
}
TarsadalmiCardHolder.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
}
TarsadalmiCardHolder.defaultProps = {
  cards: [],
}
export default TarsadalmiCardHolder
