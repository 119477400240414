import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"
import { MediaQueries } from "../../utils/responsive"

const StyledCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  box-shadow: 0 0 5px #00000066, 0 0 15px #00000033;
  .my-card-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    .gatsby-image-wrapper {
      width: 100%;
      z-index: -1;
      height: 280px;
      @media ${MediaQueries.desktop} {
        height: ${props => `${280 - props.index * 40}px`};
      }
    }
    h2 {
      position: absolute !important;
      font-size: 2.4rem;
      top: 24%;
      padding: 0 1rem 0 2rem;
      width: 100%;
      line-height: 1.25;
      color: ${props => props.theme.colors.pink};
      text-transform: uppercase;
      @media ${MediaQueries.tablet} {
        font-size: 3.6rem;
      }
      @media ${MediaQueries.desktop} {
        top: ${props => `${24 + props.index * 2}%`};
      }
    }
  }
  .my-card-body {
    padding: 0 2rem;
    margin-top: 2rem;
    p {
      text-align: justify;
    }
    div > ul > li {
      margin-bottom: 0;
    }
    .highlight {
      color: ${props => props.theme.colors.pink};
    }
  }
  .my-card-footer {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img {
      width: 77px;
      height: auto;
      margin: 0 3rem 3rem 0;
    }
    .gatsby-image-wrapper {
      max-width: 60px;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }
`

const Card = props => {
  return (
    <StyledCard index={props.index}>
      <div className="my-card-header">
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          className="bg-image"
          alt=""
          width={360}
          // placeholder="blurred"
          src={"../../images/Ticketbe.png"}
          formats={["auto", "webp", "avif"]}
        />
        <h2>{props.title}</h2>
      </div>
      <div className="my-card-body">
        <SanityBlockContent
          key={(Math.random() * 1000 + 12).toString()}
          blocks={props.description}
          projectId={sanityClientConfig.sanity.projectId}
          dataset={sanityClientConfig.sanity.dataset}
          imageOptions={{ w: 200, h: 100, fit: "max" }}
          serializers={sanitySerializers}
        />
      </div>
      <div className="my-card-footer">
        {typeof props.image === "string" ? (
          <img src={props.image} alt={""} />
        ) : (
          <GatsbyImage image={props.image} alt="" width={77} />
        )}
      </div>
    </StyledCard>
  )
}

Card.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
}

export default Card
