import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import MyImage from "../MyImage"
import PropTypes from "prop-types"
import { MediaQueries } from "../../utils/responsive"
import { Container, Row, Col } from "reactstrap"

const StyledOrgsComponent = styled(Container)`
  margin-top: ${props => `${props.theme.margins.verticalDesktop / 2}px`};
  margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
  @media ${MediaQueries.mobile} {
    margin: ${props => `${props.theme.margins.verticalDesktop * 2}px auto`};
  }
  .title {
    margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
    color: ${props => props.theme.colors.pink};
    font-size: 2.8rem;
  }
  .webLink {
    font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    &:hover {
      text-decoration: none;
    }
  }
`

const TarsadalmiOrgsComponent = props => {
  const { t } = useTranslation()
  return (
    <StyledOrgsComponent>
      <Row>
        <Col xs={12} className="pb-5">
          <h2 className="title">
            {props.title === "Partnereink"
              ? t("common:company.title")
              : t("common:socialOrgs.title")}
          </h2>
        </Col>
        {props.organisations.map(o => (
          <Col
            xs={6}
            md={4}
            key={Math.random().toString() + o.name}
            className="pb-3 text-center"
          >
            <a
              className="webLink"
              href={o.url}
              alt={`${o.name} weboldal`}
              target="blank"
              rel="noopener noreferer"
            >
              {o.logo ? (
                <MyImage
                  key={Math.random().toString() + o.name}
                  gatsbyImageData={o.logo.asset.gatsbyImageData}
                  alt={o.name}
                  styles={{ width: `${props.imageSizes[0]}px` }}
                  imgStyles={{ width: `${props.imageSizes[1]}px` }}
                />
              ) : (
                <span>{o.name}</span>
              )}
            </a>
          </Col>
        ))}
      </Row>
    </StyledOrgsComponent>
  )
}
TarsadalmiOrgsComponent.propTypes = {
  title: PropTypes.string,
  organisations: PropTypes.arrayOf(PropTypes.object),
  imageSizes: PropTypes.arrayOf(PropTypes.number),
}

TarsadalmiOrgsComponent.defaultProps = {
  organisations: [],
  imageSizes: [150, 150],
}
export default TarsadalmiOrgsComponent
